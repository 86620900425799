import { useAccount, useDisconnect, useEnsAvatar, useEnsName } from 'wagmi';
import { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, DialogTrigger } from '~/ui/dialog';
import { XCircleIcon } from 'lucide-react';
import { Button } from '~/ui/button';

function WalletAccount() {
  const { address, chain } = useAccount();
  const { disconnect } = useDisconnect();
  const { data: ensName } = useEnsName({ address });
  const { data: ensAvatar } = useEnsAvatar({ name: ensName! });

  const [connectedButtonText, setConnectedButtonText] = useState('Connected');

  function clickDisconnect() {
    disconnect();
    setConnectedButtonText('Connect');
  }

  return (
    <Dialog>
      <DialogTrigger tabIndex={-1} asChild>
        <Button tabIndex={-1}>Wallet</Button>
      </DialogTrigger>

      <DialogContent tabIndex={-1} className='w-full'>
        <DialogTitle>Wallet Account</DialogTitle>
        <div className='flex flex-col w-full gap-4 p-4 overflow-hidden'>
          <div className='flex items-center justify-between w-full gap-4'>
            {connectedButtonText}
            <Button type='button' variant='destructive' onClick={clickDisconnect}>
              <XCircleIcon className='w-4 h-4' />
              <span>Disconnect</span>
            </Button>
          </div>
          {chain?.name && <div>Network: {chain.name}</div>}
          {ensAvatar && <img alt='ENS Avatar' src={ensAvatar} />}
          {address && <div className='truncate'>{address}</div>}
          {ensName && <div className='truncate'>{ensName}</div>}
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default WalletAccount;
